/**
 * Copyright 2021 ZUP IT SERVICOS EM TECNOLOGIA E INOVACAO SA
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  FormControl,
  FormLabel,
  Checkbox as CheckboxMui,
} from '@material-ui/core';
import { connect, useField } from 'formik';
import React from 'react';

interface Props {
  initialValue?: boolean;
  name?: string;
  disabled?: boolean;
  onChangeValue?: (isChecked: boolean) => void;
  label?: string;
}

function Checkbox({ name, label, disabled = false }: Props) {
  const [field] = useField(name);

  return (
    <FormControl style={{ display: 'inline' }}>
      {label && <FormLabel>{label}</FormLabel>}
      <CheckboxMui
        id={name}
        name={name}
        onChange={field.onChange}
        checked={field.checked}
        value={field.value}
        disableRipple
        disableTouchRipple
        disableFocusRipple
        disabled={disabled}
      />
    </FormControl>
  );
}

export default connect(Checkbox);
