/**
 * Copyright 2021 ZUP IT SERVICOS EM TECNOLOGIA E INOVACAO SA
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import Styled from './styled';
import { Icon } from 'components';

interface Props {
  isVisible: boolean;
  message: string;
  type: 'success' | 'error' | 'warning';
}

const Flash: React.FC<Props> = ({ isVisible, message, type }) => {
  return (
    <>
      <Styled.Wrapper
        type={type}
        id={`flash-message-${type}`}
        isVisible={isVisible}
      >
        <Icon name={type} size="16px" />

        <Styled.Text id="flash-message">{message}</Styled.Text>
      </Styled.Wrapper>
    </>
  );
};

export default Flash;
